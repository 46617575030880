import React from "react";
import Icon, { IconType } from "../../Icon";

const Socials: React.FC<{}> = () => {
  const icons = [
    {
      src: "https://www.facebook.com/perpetualabs/",
      icon: IconType.facebook
    },
    {
      src: "https://www.instagram.com/perpetua.io/",
      icon: IconType.instagram
    },
    {
      src: "https://www.linkedin.com/company/perpetua-labs/",
      icon: IconType.linkedin
    },
    {
      src: "https://twitter.com/PerpetuaLabs",
      icon: IconType.twitter
    }
    // {
    //   src: "https://www.youtube.com/channel/UCVPWE5c8kRN6QOcp8ntIbTw",
    //   icon: IconType.youtube
    // }
  ];

  return (
    <div className="flex items-center space-x-3 md:mt-0 text-gray-2">
      {icons?.map((icon, i) => {
        return (
          <a key={i} className="flex items-center" href={icon.src}>
            <Icon
              className="text-gray fill-current h-[15px]"
              iconType={icon.icon}
            />
          </a>
        );
      })}
    </div>
  );
};

export default Socials;
