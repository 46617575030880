import React, { useEffect, useState } from "react";
import RightArrow from "../../../images/right-arrow.svg";
import Button, { ButtonVariant } from "../../Button";
import EmbeddedCta from "../EmbeddedCta";
import Close from "../../../images/close.svg";
import cn from "classnames";
import { useTranslation } from "react-i18next";

const EmbeddedCtaPop: React.FC<{}> = () => {
  const [embeddedOpen, setEmbeddedOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <Button
        variant={ButtonVariant.tertiary}
        onClick={() => setEmbeddedOpen(!embeddedOpen)}
        className="flex items-center pt-2"
      >
        {t("Subscribe & Grow")}
        <RightArrow className="ml-2" />
      </Button>
      <div
        className={
          embeddedOpen
            ? "z-50 fixed block top-0 left-0 bottom-0 right-0 h-full w-full opacity-1"
            : "hidden absolute opacity-0 bottom-0"
        }
      >
        <div className="fixed h-full w-full overflow-hidden">
          <div
            className={cn(
              "z-50 transition-all ease-in duration-300 absolute w-full max-w-[280px] sm:max-w-[300px] md:max-w-[500px] right-10 bottom-24 md:right-24",
              embeddedOpen
                ? "left-1/2 -translate-x-1/2 md:left-1/2 md:-translate-x-1/2 lg:left-3/4"
                : "translate-x-[110vw] absolute"
            )}
          >
            <EmbeddedCta
              buttonText={t("Sign Up")}
              title={`${t(
                "Retail media trends, resources, news, and updates, right to your inbox"
              )} 🌿`}
              textFieldPlaceholder={t("Email address")}
              successText={t(
                "Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way."
              )}
              setEmbeddedOpen={setEmbeddedOpen}
            />
            <button
              onClick={() => setEmbeddedOpen(false)}
              className="absolute bg-indigo-1 h-10 w-10 rounded-full flex items-center justify-center focus:outline-none right-1/2 translate-x-1/2 md:translate-x-0 -top-4 md:-right-3"
            >
              <Close />
            </button>
          </div>
          {embeddedOpen && (
            <div
              className="absolute h-full w-full bg-purple-2-dark bg-opacity-80"
              onClick={() => setEmbeddedOpen(false)}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default EmbeddedCtaPop;
